import { AxiosRequestConfig } from "axios";
import http from "../base/http.service";
import {
  UserLoginDto,
  ForgotPasswordDto,
  TokenDto,
  ChangePasswordDTO,
  TwoFactorDTO,
  UpdateUserProfileDto,
} from "./auth.dto";

const services = {
  Login(dto: UserLoginDto, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<TokenDto>("auth/login", dto, config);
  },
  ForgotPassword(
    dto: ForgotPasswordDto,
    config?: AxiosRequestConfig<any> | undefined
  ) {
    return http.post("auth/forgotPassword", dto, config);
  },
  RefreshToken(dto: TokenDto, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<TokenDto>("auth/refreshToken", dto, config);
  },
  ResetPassword(
    dto: ChangePasswordDTO,
    config?: AxiosRequestConfig<any> | undefined
  ) {
    return http.post<string>("auth/resetPassword", dto, config);
  },
  Verify2FA(dto: TwoFactorDTO, config?: AxiosRequestConfig<any> | undefined) {
    return http.post<TokenDto>("auth/verify2FA", dto, config);
  },
  VerifyReset2FA(
    dto: TwoFactorDTO,
    config?: AxiosRequestConfig<any> | undefined
  ) {
    return http.post<TokenDto>("auth/verify2FA", dto, config);
  },
  Update(
    dto: UpdateUserProfileDto,
    config?: AxiosRequestConfig<any> | undefined
  ) {
    return http.post<TokenDto>("auth/update", dto, config);
  },
};

export default services;
