import React from "react";
import { RequireAuth } from "./utils/auth";
import { Route, Routes } from "react-router-dom";
const Products = React.lazy(() => import("./pages/products/Products"));
const ResetPasswordPage = React.lazy(
  () => import("./pages/authorization/ResetPasswordPage")
);
const TwoFAPage = React.lazy(() => import("./pages/authorization/TwoFAPage"));
const UnauthorizedPage = React.lazy(
  () => import("./pages/authorization/UnauthorizedPage")
);
const App = React.lazy(() => import("./App"));
const HomePage = React.lazy(() => import("./pages/home/HomePage"));
const LoginPage = React.lazy(() => import("./pages/authorization/LoginPage"));
const ApplicationPage = React.lazy(
  () => import("./pages/make-application/ApplicationPage")
);
const ForgotPasswordPage = React.lazy(
  () => import("./pages/authorization/ForgotPasswordPage")
);
const LandingPage = React.lazy(() => import("./pages/landing/LandingPage"));
const SectorsPage = React.lazy(
  () => import("./pages/master-data/sectors/SectorsPage")
);
const StandardsPage = React.lazy(
  () => import("./pages/master-data/standards/StandardsPage")
);
const ProfileSettingsPage = React.lazy(
  () => import("./pages/profile/ProfileSettingsPage")
);
const RolesPage = React.lazy(() => import("./pages/roles/RolesPage"));
const UsersPage = React.lazy(() => import("./pages/users/UsersPage"));
const AuditorsPage = React.lazy(
  () => import("./pages/master-data/auditors/AuditorsPage")
);
const DutiesPage = React.lazy(() => import("./pages/duties/DutiesPage"));
const DutiesDetailPage = React.lazy(
  () => import("./pages/duties/DutiesDetailPage")
);
const DutiesEditPage = React.lazy(
  () => import("./pages/duties/DutiesEditPage")
);
const NewApplicationPage = React.lazy(
  () => import("./pages/new-application/NewApplicationPage")
);
const EvaluationChecklist = React.lazy(
  () => import("./pages/duties/EvaluationChecklist")
);
const ApplicationsPage = React.lazy(
  () => import("./pages/applications/ApplicationsPage")
);
const UploadOfferPage = React.lazy(
  () => import("./pages/make-application/UploadOfferPage")
);
const UploadContractPage = React.lazy(
  () => import("./pages/make-application/UploadContractPage")
);
const AECPage = React.lazy(() => import("./pages/aec/AECPage"));
const CompletedApplicationPage = React.lazy(
  () => import("./pages/make-application/CompletedApplicationPage")
);
const FRCAdmins = React.lazy(
  () => import("./pages/master-data/FRCAdmins/FRCAdmins")
);
const DraftApplications = React.lazy(
  () => import("./pages/draft-applications/DraftApplications")
);
const CICQuestionsPage = React.lazy(
  () => import("./pages/cic/CICQuestionsPage")
);
const MyBills = React.lazy(() => import("./pages/my-bills/MyBills"));
const ProcessTypes = React.lazy(
  () => import("./pages/process-types/ProcessTypes")
);
const CICAnswersPage = React.lazy(() => import("./pages/cic/CICAnswersPage"));
const MyApplicationDocuments = React.lazy(
  () => import("./pages/my-application-documents/MyApplicationDocuments")
);
const CICAnswerDetail = React.lazy(() => import("./pages/cic/CICAnswerDetail"));
const FileTypes = React.lazy(() => import("./pages/file-types/FileTypes"));
const Customers = React.lazy(() => import("./pages/customers/Customers"));
const ApplicationHistory = React.lazy(
  () => import("./pages/applications/ApplicationHistory")
);
const MyDocuments = React.lazy(
  () => import("./pages/my-documents/MyDocuments")
);

interface DynamicRoute {
  path: string;
  element: JSX.Element;
  permission?: string[];
}

const globalRoutes = [
  { path: "/", element: <LandingPage /> },
  { path: "/login", element: <LoginPage /> },
  { path: "/reset", element: <ResetPasswordPage /> },
  { path: "/twofactor", element: <TwoFAPage /> },
  { path: "/accessdenied", element: <UnauthorizedPage /> },
  { path: "/forgotpassword", element: <ForgotPasswordPage /> },
  { path: "/apply", element: <ApplicationPage /> },
  { path: "/upload-offer", element: <UploadOfferPage /> },
  { path: "/upload-contract", element: <UploadContractPage /> },
  { path: "/application-review", element: <CompletedApplicationPage /> },
];

const dynamicRoutes: DynamicRoute[] = [
  { path: "", element: <HomePage />, permission: [] },
  {
    path: "profile",
    element: <ProfileSettingsPage />,
    permission: [],
  },
  {
    path: "sectors",
    element: <SectorsPage />,
    permission: ["Host.DynamicRoles.Sectors"],
  },
  {
    path: "standards",
    element: <StandardsPage />,
    permission: ["Host.DynamicRoles.Standards"],
  },
  {
    path: "roles",
    element: <RolesPage />,
    permission: ["Host.DynamicRoles.Roles"],
  },
  {
    path: "users",
    element: <UsersPage />,
    permission: ["Host.DynamicRoles.Users.Create"],
  },
  {
    path: "auditors",
    element: <AuditorsPage />,
    permission: ["Host.DynamicRoles.Auditors"],
  },
  {
    path: "frc-admins",
    element: <FRCAdmins />,
    permission: ["Host.DynamicRoles.FRCAdmins"],
  },
  {
    path: "duties",
    element: <DutiesPage />,
    permission: ["Host.DynamicRoles.Applications.Duties"],
  },
  {
    path: "duties/detail/:id",
    element: <DutiesDetailPage />,
    permission: ["Host.DynamicRoles.Applications.Detail"],
  },
  {
    path: "duties/edit/:id",
    element: <DutiesEditPage />,
    permission: ["Host.DynamicRoles.Applications.Edit"],
  },
  {
    path: "duties/history/:id",
    element: <ApplicationHistory />,
    permission: ["Host.DynamicRoles.Applications.Detail"],
  },
  {
    path: "duties/evaluation-checklist/:id",
    element: <EvaluationChecklist />,
    permission: ["Host.DynamicRoles.Applications.Duties"],
  },
  {
    path: "newapply",
    element: <NewApplicationPage />,
    permission: ["Host.DynamicRoles.Applications.Create"],
  },
  {
    path: "applications",
    element: <ApplicationsPage />,
    permission: ["Host.DynamicRoles.Applications"],
  },
  {
    path: "my-application-documents",
    element: <MyApplicationDocuments />,
    permission: ["Host.DynamicRoles.Customer"],
  },
  {
    path: "my-documents",
    element: <MyDocuments />,
    permission: ["Host.DynamicRoles.Customer"],
  },
  {
    path: "my-bills",
    element: <MyBills />,
    permission: ["Host.DynamicRoles.Customer"],
  },
  {
    path: "draft-applications",
    element: <DraftApplications />,
    permission: ["Host.DynamicRoles.Applications"],
  },
  {
    path: "aec",
    element: <AECPage />,
    permission: ["Host.DynamicRoles.AECQuestions"],
  },
  {
    path: "cic-questions",
    element: <CICQuestionsPage />,
    permission: ["Host.DynamicRoles.CICQuestions"],
  },
  {
    path: "cic-answers/:appUID",
    element: <CICAnswersPage />,
    permission: ["Host.DynamicRoles.CICAnswers"],
  },
  {
    path: "cic-answers/detail/:id",
    element: <CICAnswerDetail />,
    permission: ["Host.DynamicRoles.CICAnswers"],
  },
  {
    path: "process-types",
    element: <ProcessTypes />,
    permission: ["Host.DynamicRoles.ProcessTypes"],
  },
  {
    path: "file-types",
    element: <FileTypes />,
    permission: ["Host.DynamicRoles.FileTypes"],
  },
  {
    path: "customers",
    element: <Customers />,
    permission: ["Host.DynamicRoles.Applications.Duties"],
  },
  {
    path: "products",
    element: <Products />,
    permission: [],
  },
];

export default function AppRouter() {
  return (
    <Routes>
      {globalRoutes.map((route, index) => (
        <Route key={index} path={route.path} element={route.element} />
      ))}
      <Route path="/app" element={<App />}>
        {dynamicRoutes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            element={
              route.permission ? (
                <RequireAuth permissions={route.permission}>
                  {route.element}
                </RequireAuth>
              ) : (
                route.element
              )
            }
          />
        ))}
      </Route>
    </Routes>
  );
}
