import React, {
  useState,
  createContext,
  Dispatch,
  SetStateAction,
  useEffect,
} from "react";

export const LayoutContext = createContext({} as LayoutContextProps);

export const LayoutProvider = ({ children }: { children: React.ReactNode }) => {
  const [title, setTitle] = useState<string | undefined>();
  const [topbarTitleTemplate, setTopbarTitleTemplate] = useState<
    React.ReactNode | undefined
  >();
  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
  const [iconMenuActive, setIconMenuActive] = useState(false);
  const [isScrolling, setIsScrolling] = useState<number>();
  const [userPhoto, setUserPhoto] = useState<string | undefined>();

  let menuClick = false;
  let mobileTopbarMenuClick = false;

  const onWrapperClick = (event: any) => {
    if (!menuClick) {
      setMobileMenuActive(false);
    }

    if (!mobileTopbarMenuClick) {
      setMobileTopbarMenuActive(false);
    }

    mobileTopbarMenuClick = false;
    menuClick = false;
  };

  const onToggleMenuClick = (event: any) => {
    menuClick = true;
    if (isMobile()) {
      setMobileMenuActive((prevState) => !prevState);
    } else {
      setIconMenuActive((prevState) => !prevState);
    }

    event.preventDefault();
  };

  const onSidebarClick = () => {
    menuClick = true;
  };

  const onMobileTopbarMenuClick = (event: any) => {
    mobileTopbarMenuClick = true;

    setMobileTopbarMenuActive((prevState) => !prevState);
    event.preventDefault();
  };

  const onMobileSubTopbarMenuClick = (event: any) => {
    mobileTopbarMenuClick = true;

    event.preventDefault();
  };

  const isMobile = () => {
    return window.innerWidth <= 992;
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolling(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const value = {
    mobileMenuActive,
    setMobileMenuActive,
    mobileTopbarMenuActive,
    setMobileTopbarMenuActive,
    iconMenuActive,
    setIconMenuActive,
    onWrapperClick,
    onToggleMenuClick,
    onSidebarClick,
    onMobileTopbarMenuClick,
    onMobileSubTopbarMenuClick,
    title,
    setTitle,
    topbarTitleTemplate,
    setTopbarTitleTemplate,
    isMobile,
    isScrolling,
    userPhoto,
    setUserPhoto,
  };

  return (
    <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>
  );
};

export interface LayoutContextProps {
  mobileMenuActive: boolean;
  setMobileMenuActive: Dispatch<SetStateAction<boolean>>;
  mobileTopbarMenuActive: boolean;
  setMobileTopbarMenuActive: Dispatch<SetStateAction<boolean>>;
  iconMenuActive: boolean;
  setIconMenuActive: Dispatch<SetStateAction<boolean>>;
  onWrapperClick: (event: any) => void;
  onToggleMenuClick: (event: any) => void;
  onSidebarClick: () => void;
  onMobileTopbarMenuClick: (event: any) => void;
  onMobileSubTopbarMenuClick: (event: any) => void;
  title?: string | undefined;
  setTitle: Dispatch<SetStateAction<string | undefined>>;
  topbarTitleTemplate?: React.ReactNode | undefined;
  setTopbarTitleTemplate: Dispatch<SetStateAction<React.ReactNode | undefined>>;
  isMobile: () => boolean;
  isScrolling: number | undefined;
  userPhoto: string | undefined;
  setUserPhoto: Dispatch<SetStateAction<string | undefined>>;
}
